<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card">
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-7">
                <label for="pessoa">Informe a Pessoa</label>
                <Dropdown 
                v-model="selectedPessoa" 
                :options="pessoas" 
                :filter="true" 
                :showClear="true" 
                optionValue="id"
                optionLabel="nome" placeholder="Selecione um Servidor" class="p-mr-2 p-mb-2" id="pessoa" />
              </div>
              <div class="p-field p-col-12 p-md-4">
                <label for="data">Informe o Mês</label>
                <Calendar id="data" v-model="data" view="month" dateFormat="mm/yy" placeholder="mm/yyyy"
                  :yearNavigator="true" yearRange="2000:2030" />
              </div>

              <div class="p-field p-col-12 p-md-1">
                <br />
                <Button icon="pi pi-search" style="margin-top: 7px" @click="listarRegistroBatida()" />
              </div>
            </div>
          </template>
          <template v-slot:right>
            <Button label="Gerar Relatório" icon="pi pi-upload" class="p-button-help p-mb-2" :disabled="!registros"
              @click="exportarRelatorioPDF" />
          </template>
        </Toolbar>

        <div class="p-grid crud-demo">
          <div class="p-col-12 p-md-12">
            <DataTable ref="dt" :value="registros" class="p-datatable-sm p-datatable-striped" v-if="apresentar"
              :rowClass="dayOfWeek" dataKey="0">
              <template #header>
                <div class="p-fluid p-formgrid p-grid" id="dadosPonto">
                  <div class="p-field p-col-12 p-md-12">
                    <div class="p-fluid p-formgrid p-grid">
                      <div class="p-col-12 p-md-2">
                        <div class="p-d-flex p-ai-center p-jc-center ctisDiv">
                          <img src="assets/layout/images/sespa.png" alt="SESPA" width="150" class="ctis"
                            style="margin-top: 45px" />
                        </div>
                      </div>
                      <div class="p-col-12 p-md-9" v-if="pessoa[0]">
                        <label>Nome: {{ pessoa[0][0] }}</label><br />
                        <label>Matricula: {{ pessoa[0][1] }}</label><br />
                        <label v-if="pessoa[0][3]">Cargo: {{ pessoa[0][3] }}</label>
                        <label v-else>Cargo: SEM CARGO</label><br />

                        <label v-if="pessoa[0][4]">Função: {{ pessoa[0][4] }}.</label>
                        <label v-else>Função: SEM FUNÇÃO</label> <br />
                        <label>Atuação: {{ pessoa[0][5] }}</label><br />
                        <label>Situação: {{ pessoa[0][2] }}</label><br />
                        <label>Horario: {{ pessoa[0][6] }}</label><br />
                        <label for="mes_ano">Registro de Batidas - {{ mesAno }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template #empty>
                <div class="p-text-center">Nenhum resultado encontrado...</div>
              </template>
              <Column header="Dia/Data">
                <template #body="slotProps">
                  <div>
                    <b>{{ slotProps.data.day }}</b> - {{ slotProps.data.work }}
                  </div>
                </template>
              </Column>

              <Column header="Batidas">
                <template #body="slotProps">
                  <p>
                    {{ slotProps.data.clock ? slotProps.data.clock : 'X' }}
                  </p>
                </template>
              </Column>

              <Column header="Observações">
                <template #body="slotProps">
                  <p>
                    {{ slotProps.data.direction ? slotProps.data.direction : 'X' }}
                  </p>
                </template>
              </Column>

            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import AuthService from "@/service/Auth/AuthService";

import PessoaService from "@/service/Pessoa/PessoaService";
import BatidaService from "@/service/Batida/BatidaService";

import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  data() {
    return {
      pessoa: {
        nome: null,
        mf: null,
        situacao: null,
        cargo: null,
        funcao: null,
        atuacao: null,
        horario: null,
      },
      pessoas: null,
      selectedPessoa: null,
      data: null,
      mesAno: null,
      registros: null,
      apresentar: false,
    };
  },
  authService: null,
  pessoaService: null,
  batidaService: null,
  created() {
    this.authService = new AuthService();
    this.pessoaService = new PessoaService();
    this.batidaService = new BatidaService();
  },
  mounted() {
    this.listPessoas();    
  },
  methods: {
    formatBatidas(batidas) {
      if (batidas) {
        // Adiciona "|" entre as batidas
        return batidas.replace(/s+/g, '|');
      } else {
        return 'X';
      }
    },
    formatObservacoes(observacoes) {
      if (observacoes) {
        return observacoes.replace(/s+/g, '|');
      } else {
        return 'X';
      }
    },
    exportarRelatorioPDF() {    
      const pdf = new jsPDF();
      // Add SESPA logo
      pdf.addImage("assets/layout/images/sespa.png", "JPEG", 65,10,80,15);
      // Set font size
      pdf.setFontSize(6.5);
      
      pdf.text(`Nome: ${this.pessoa[0][0]}.`, 25, 29.5);
      
      pdf.text(`Matricula: ${this.pessoa[0][1]}.`, 25, 31.5);
      if (this.pessoa[0][3]) {
        pdf.text(`Cargo: ${this.pessoa[0][3]}.`, 25, 33.5);
      } else {
        pdf.text(`Cargo: SEM CARGO.`, 25, 33.5);
      }
      if (this.pessoa[0][4]) {
        pdf.text(`Função: ${this.pessoa[0][4]}.`, 25, 35.5);
      } else {
        pdf.text(`Função: SEM FUNÇÃO.`, 25, 35.5);
      }
      pdf.text(`Atuação: ${this.pessoa[0][5]}.`, 25, 37.5);
      pdf.text(`Situação: ${this.pessoa[0][2]}.`, 25, 39.5);

      if (this.pessoa[0][6] === null) {
        pdf.text(`Horario: 00:00:00.`, 25, 41.5);
      } else {
        pdf.text(`Horario: ${this.pessoa[0][6]}.`, 25, 41.5);
      }
      pdf.text(`Referencia: ${this.mesAno.toUpperCase()}. `, 25, 43.5);
      
      pdf.setFont("","bold").setFontSize(10).text('RELATÓRIO MENSAL',105, 50,null,null,"center");

      const columns = ["Dia-Data", "Batidas", "Observações"];
      const data = this.registros.map((registro) => [
        `${registro.day} - ${registro.work}`,
        this.formatBatidas(registro.clock),
        this.formatObservacoes(registro.direction),
      ]);

      pdf.autoTable({
        head: [columns],
        body: data,
        startY: 53,
        margin: { top: 55, left: 25 },
        styles: {
          fontSize: 5.5,
          cellPadding: 1.7,
          valign: 'middle',
          halign: '',
          fillColor: [255, 255, 255], // Cor de fundo do cabeçalho (Azul claro)
          textColor: [0, 0, 0], // Cor do texto do cabeçalho (Azul mais escuro)
          lineWidth: 0.3, // Espessura da borda
        },
        alternateRowStyles: { fillColor: [192, 192, 192] }, // Cor de fundo para linhas alternadas (Verde claro)
        columnStyles: {
          0: { cellWidth: 50 }, // Ajuste a largura da coluna conforme necessário
          1: { cellWidth: 40 },
          2: { cellWidth: 70 },
        },
        headeStyles: {
          theme: 'striped', // Adiciona a seta de ordenação nas colunas do cabeçalho
          fillColor: [21, 111, 183], // Cor de fundo do cabeçalho (Azul claro)
          textColor: [255, 255, 255],
        },
        bodyStyles: {
          rowAccessories: { fillColor: [255, 255, 255] }, // Cor de fundo padrão para outras linhas (Branco)
        },
      });

      const totalPagesExp = pdf.internal.getNumberOfPages();
      const pageStr = "{total_pages_count_string}";

      const textToDisplay = `PÁG. ${pageStr.replaceAll("{total_pages_count_string}", totalPagesExp)} / ${totalPagesExp}`;

      pdf.setFontSize(10);
      pdf.text(textToDisplay, 105, pdf.internal.pageSize.height - 10, {
        align: "center",
      });
      
      pdf.setFont("helvetica", "normal")
        .setFontSize(9.5)
        .text(
          "Coordenação de Tecnologia e Informática em Saúde - CTIS \n" +
          "End: Tva. Lomas Valentinas, 2190, Bairro Marco, CEP: 66093-677, Belém, Pará \n" +
          "Contato: +55, 91 4006-4399 | 4006-4328 www.saude.pa.gov.br",
          105,
          275,
          null,
          null,
          "center"
      );
      
      window.open(pdf.output("bloburl"));
    },
    dayOfWeek(day) {
      if (day.work === 'SÁBADO' || day.work === 'DOMINGO') {
        return 'row-accessories';
      }
    },
    formatarHora(date) {
      if (date) {
        return Moment(date).utc().format("H:mm:ss");
      }
      return;
    },
    formatarDiaSemana(data) {
      let d = new Date(data.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
      var semana = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
      return semana[d.getDay()];
    },
    listPessoas() {
      this.pessoaService.listar().then((data) => {
        this.pessoas = data;        
      });
    },
    listOcorrenciasPessoa(codigo) {
      this.pessoaService.listarOcorrencias(codigo).then((data) => {
        this.pessoa = data;
      });
    },
    listarRegistroBatida() {
      

      if (this.selectedPessoa !== null && this.data !== null) {
        let mes = this.data.getMonth() + 1;
        let ano = this.data.getFullYear();        
        this.batidaService
          .listarServidorMensal(this.selectedPessoa, ano, mes)
          .then((data) => {            
            if (data.length == 0) {
              this.apresentar = false;
              alert(
                "Não foram encontrados registros de batidas para a data " +
                Moment(this.data).utc().format("DD/MM/YYYY") +
                "."
              );
            } else {
              this.registros = data;
              this.mesAno =
                "" +
                this.data.toLocaleString("default", { month: "long" }) +
                "/" +
                ano;
              this.listOcorrenciasPessoa(this.selectedPessoa);
              this.apresentar = true;
            }
          });
      } else {
        alert("Campo de pessoa e mês são obrigatórios.");
      }
    },
    verificarInconsistencia(dados) {
      if (dados.indexOf("  ENTRADA  |  ENTRADA  ") != -1) {
        return "DOIS OU MAIS REGISTROS DE ENTRADAS.";
      } else if (dados.indexOf("  SAÍDA  |  SAÍDA") != -1) {
        return "DOIS OU MAIS REGISTROS DE SAÍDAS.";
      } else if (dados === "SAÍDA") {
        return "SOMENTE REGISTRO DE SAÍDA.";
      } else if (dados === "ENTRADA") {
        return "SOMENTE REGISTRO DE ENTRADA.";
      } else {
        return "SEM INCONSISTÊNCIA.";
      }
    },
  }
};

</script>

<style scoped lang="scss">
:deep(.row-accessories) {
  background-color: rgba(0, 0, 0, .15) !important;

}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.visitante-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .visitante-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-datatable-sm .p-datatable-tbody>tr>td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {

      .p-datatable-thead>tr>th,
      .p-datatable-tfoot>tr>td {
        display: none !important;
      }

      .p-datatable-tbody>tr>td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: left;
        }

        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}

#dadosPonto {
  font-size: 12.5px;
}

.product-badge-su {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  background: #c8e6c9;
  color: #256029;
}

.product-badge-error {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  background: #c8e6c9;
  color: #256029;
  background: #ffcdd2;
  color: #c63737;
}

.product-badge {
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.highlight {
  background-color: cyan; // ou qualquer outra cor de destaque desejada
}
</style>